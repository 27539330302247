import { posthog } from "posthog-js";
import { useEffect, useState } from "react";
import { ResponsiveDialog } from "./ResponsiveDialog";
import { Button } from "./ui/button";

export function cookieConsentGiven() {
  const cookieConsent = localStorage.getItem("cookie_consent");
  if (!cookieConsent) {
    return "undecided";
  }
  return cookieConsent;
}

export function CookieBanner() {
  const [consentGiven, setConsentGiven] = useState("");

  useEffect(() => {
    setConsentGiven(cookieConsentGiven());
  }, []);

  useEffect(() => {
    if (consentGiven !== "") {
      posthog.set_config({ persistence: consentGiven === "yes" ? "localStorage+cookie" : "memory" });
    }
  }, [consentGiven]);

  const handleAcceptCookies = () => {
    localStorage.setItem("cookie_consent", "yes");
    setConsentGiven("yes");
  };

  const handleDeclineCookies = () => {
    localStorage.setItem("cookie_consent", "no");
    setConsentGiven("no");
  };

  return (
    <ResponsiveDialog open={consentGiven === "undecided"} onOpenChange={() => {}} showCancel={false}>
      <div className="space-y-4">
        <h2 className="text-primaryText text-lg font-semibold">Cookie Settings</h2>
        <div className="space-y-2">
          <p className="text-secondaryText text-sm">
            We use cookies to enhance your experience and analyze our website&apos;s performance. This data helps us
            improve our services and provide you with better features.
          </p>
          <p className="text-secondaryText text-sm">
            You can choose to accept or decline these cookies. Essential cookies for basic functionality will always be
            enabled.
          </p>
        </div>
        <div className="flex flex-col gap-2 pt-2 sm:flex-row sm:gap-3">
          <Button variant="default" size="default" onClick={handleAcceptCookies} className="flex-1">
            Accept All Cookies
          </Button>
          <Button variant="outline" size="default" onClick={handleDeclineCookies} className="flex-1">
            Essential Cookies Only
          </Button>
        </div>
      </div>
    </ResponsiveDialog>
  );
}
