import * as Sentry from "@sentry/remix";
import { createContext, useContext, useCallback, useRef } from "react";
import { throwIfNull } from "~/utils";

interface TimingContextType {
  startTiming: () => void;
  endTiming: () => void;
}

const TimingContext = createContext<TimingContextType | null>(null);

export const MessageToStreamTimingProvider = ({ children }: { children: React.ReactNode }) => {
  const timingRef = useRef<{
    startTime: number | null;
    span: Sentry.Span | null;
  }>({ startTime: null, span: null });

  const startTiming = useCallback(() => {
    timingRef.current.startTime = performance.now();
    timingRef.current.span = Sentry.startInactiveSpan({
      name: "MessageToStreamTTFB",
      op: "message_to_stream",
      forceTransaction: true,
    });
  }, []);

  const endTiming = useCallback(() => {
    if (timingRef.current.startTime !== null) {
      const endTime = performance.now();
      const duration = endTime - timingRef.current.startTime;
      console.log(`Time from sendMessage to live stream first byte: ${duration.toFixed(2)}ms`);
      timingRef.current.startTime = null;
      timingRef.current.span?.end();
      timingRef.current.span = null;
    }
  }, []);

  return <TimingContext.Provider value={{ startTiming, endTiming }}>{children}</TimingContext.Provider>;
};

export const useMessageToStreamTiming = () => {
  return throwIfNull(useContext(TimingContext));
};
