import { useMediaQuery } from "usehooks-ts";
import { Button, Dialog, DialogContent, Drawer, DrawerContent } from "~/components";
import { cn } from "~/utils";

export const ResponsiveDialog = ({
  children,
  open,
  onOpenChange,
  showCancel = true,
}: {
  children: React.ReactNode;
  open: boolean;
  onOpenChange: (open: boolean) => void;
  showCancel?: boolean;
}) => {
  const isDesktop = useMediaQuery("(min-width: 768px)");

  if (isDesktop) {
    return (
      <Dialog open={open} onOpenChange={showCancel ? onOpenChange : undefined}>
        <DialogContent
          aria-describedby={undefined}
          className={cn(!showCancel && "data-[state=open]:pointer-events-none [&>button]:hidden")}
        >
          {children}
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Drawer open={open} onOpenChange={showCancel ? onOpenChange : undefined} dismissible={showCancel}>
      <DrawerContent className={cn(!showCancel && "[&>div:first-child]:hidden")}>
        <div className="p-4">{children}</div>
        {showCancel && (
          <div className="p-4 pt-2">
            <Button variant="outline" className="w-full" onClick={() => onOpenChange(false)}>
              Cancel
            </Button>
          </div>
        )}
      </DrawerContent>
    </Drawer>
  );
};
